<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as QUOTATION_TYPES } from './store'
import { ROUTES as QUOTATION_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../components/thux-list/ThuxListMixin'

import QuotationTable from './QuotationTable'
import QuotationCommonFilters from './QuotationCommonFilters'
import QuotationEdit from './QuotationEdit.vue'

export default {
  name: 'QuotationList',
  mixins: [ThuxListMixin],
  watch: {
    isYardQuotation () {
      this.cleanAllData()
      this.getComponentList()
    }
  },
  components: {
    'component-table': QuotationTable,
    'common-filters': QuotationCommonFilters,
    'component-edit': QuotationEdit
  },
  data () {
    return {
      QUOTATION_ROUTES,
      title: this.$t('Quotations'),
      rolePerm: ['quotation_quotation_list'],
      actionEnablePermission: ['quotation_quotation_enable'],
      actionDisablePermission: ['quotation_quotation_disable'],
      searchFields: [
        {
          key: 'quotation_expired',
          placeholder: this.$t('Expired'),
          type: 'boolean',
          options: [
            { value: 1, text: this.$t('Expired') },
            { value: 0, text: this.$t('Not Expired') }
          ],
          col: 4
        },
        { key: 'filter__date_q__gte', placeholder: this.$t('Date greater than equal'), type: 'date', col: 4 },
        { key: 'filter__date_q__lte', placeholder: this.$t('Date less than equal'), minDate: 'filter__date_q__gte', type: 'date', col: 4 },
        { key: 'filter__date_firm_for__gte', placeholder: this.$t('Date firm for greater than equal'), type: 'date', col: 4 },
        { key: 'filter__date_firm_for__lte', placeholder: this.$t('Date firm for less than equal'), minDate: 'filter__date_firm_for__gte', type: 'date', col: 4 },
        { key: 'filter__customer__name__icontains', placeholder: this.$t('Customer'), type: 'text', col: 4 }
      ],
      actionsCustom: [
        {
          value: 'setAsSent',
          text: this.$t('Set as sent')
        },
        {
          value: 'setAsNotSent',
          text: this.$t('Set as not sent')
        },
        {
          value: 'exportInExcel',
          text: this.$t('Export in Excel')
        }

      ],
      actionButtons: [
        {
          title: 'Download Excel',
          iconClass: 'fas fa-file-excel',
          actionName: 'openWarningDialog'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ...QUOTATION_TYPES.GENERIC.quotation.quotation.LIST.GETTERS
    }),
    showList () {
      if (this.$route.name === this.QUOTATION_ROUTES.YARD_QUOTATION_LIST) {
        return true
      }
      if (this.$route.name === this.QUOTATION_ROUTES.QUOTATION_LIST) {
        return true
      }
      return false
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('quotation_quotation_create')
    },
    isYardQuotation () {
      if (this.$route.name === QUOTATION_ROUTES.YARD_QUOTATION_LIST) {
        return true
      }
      return false
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: QUOTATION_TYPES.GENERIC.quotation.quotation.LIST.MUTATIONS.setSelectedList,
      setSelectAll: QUOTATION_TYPES.GENERIC.quotation.quotation.LIST.MUTATIONS.setSelectAll,
      setOrderBy: QUOTATION_TYPES.GENERIC.quotation.quotation.LIST.MUTATIONS.setOrderBy,
      setList: QUOTATION_TYPES.GENERIC.quotation.quotation.LIST.MUTATIONS.setList
    }),
    ...mapActions({
      ...QUOTATION_TYPES.GENERIC.quotation.quotation.LIST.ACTIONS
    }),
    openDetailForm (id) {
      if (this.isYardQuotation) {
        this.$router.push({ name: QUOTATION_ROUTES.YARD_QUOTATION_DETAIL, params: { id: id } })
      } else {
        this.$router.push({ name: QUOTATION_ROUTES.QUOTATION_DETAIL, params: { id: id } })
      }
    },
    openEditForm () {
      if (this.isYardQuotation) {
        this.$router.push({ name: QUOTATION_ROUTES.YARD_QUOTATION_CREATE })
      } else {
        this.$router.push({ name: QUOTATION_ROUTES.QUOTATION_CREATE })
      }
    },
    getComponentList () {
      this.setList({})
      this.setOrderBy('-date_q.-number')
      if (this.$route.name === this.QUOTATION_ROUTES.YARD_QUOTATION_LIST) {
        return this.setFilters({ filter__is_yard_quotation: 'True' })
      }
      if (this.$route.name === this.QUOTATION_ROUTES.QUOTATION_LIST) {
        return this.setFilters({ filter__is_yard_quotation: 'False' })
      }
      return this.setFilters()
    },
    doCustomActions (actionType, idList, counter) {
      if (actionType === 'setAsSent') {
        idList.forEach((id) => {
          this.setAsSent(id).then(() => {
            if (counter === idList.length - 1) {
              this.getList()
              this.setSelectedList([])
            }
            counter += 1
          })
        })
      }
      if (actionType === 'setAsNotSent') {
        idList.forEach((id) => {
          this.setAsNotSent(id).then(() => {
            if (counter === idList.length - 1) {
              this.getList()
              this.setSelectedList([])
            }
            counter += 1
          })
        })
      }
      if (actionType === 'exportInExcel') {
        this.generateQuotationXlsxJob({
          file_name: this.isYardQuotation ? 'preventivi_cantieri.xlsx' : 'preventivi.xlsx',
          id__in: idList
        }).then(() => {

        })
      }
    },
    openWarningDialog () {
      this.showWarningDialog = true
      this.warningDialogMessage = `${this.$t('The quotation download has been requested, the system will generate an Excel file containing the list of quotations based on applied filters')}.`
      this.warningDialogMessage = `${this.warningDialogMessage} ${this.$t('When the required file is ready, the download link will be displayed in the in bar by pressing the downloads icon')}.`
    },
    closeWarningDialogModal () {
      this.showWarningDialog = false
      this.downloadExcelQuotationList()
    },
    downloadExcelQuotationList () {
      const formData = { filters: {} }
      Object.keys(this.filters).forEach(el => {
        if (el.startsWith('filter__')) {
          formData.filters[el.replace('filter__', '')] = this.filters[el]
        }
      })
      formData.file_name = this.isYardQuotation ? 'preventivi_cantieri.xlsx' : 'preventivi.xlsx'
      this.generateQuotationXlsxJob(formData)
    }
  }
}
</script>
