<template>
  <div>
    <thux-table
      :instance="instance"
      :instance-name="instanceName"
      :order-by-dict="orderByDict"
      :goBack="goBack"
      :list="list"
      :fieldName="fieldName"
      :fields="fields"
      :fields-column="fieldsColumn"
      :select-all="selectAll"
      :selected-list="selectedList"
      :get-local-selected-all="getLocalSelectedAll"
      :local-selected-list="localSelectedList"
      @check-all="checkAll('id')"
      @check-none="checkNone('id')"
      @set-selected-row="setSelectedRow($event, 'id')"
      :show-checks="showChecks"
      :showDetailButton="showDetailButton"
      @change-status="changeInstanceStatus"
      @sort-changed="orderTable"
      @remove-order-by="removeOrderFromTable"
      @open-detail-form="$emit('open-detail-form', $event)"
    >
      <template slot="number" slot-scope="row">
        <div>
          {{row.item.quotation_code}}
         </div>
      </template>
      <template slot="order_done" slot-scope="row">
        <div v-if="row.item.order_done">
           <i class="far fa-check-circle text-green" :title="$t('Ordered')"></i>
         </div>
        <div v-else>
          <i class="far fa-times-circle text-red" :title="$t('Not Ordered')"></i>
        </div>
      </template>
      <template slot="client_name" slot-scope="row" v-if="routeName === QUOTATION_ROUTES.YARD_QUOTATION_LIST">
        {{row.item.clients_name}}
      </template>
      <template slot="submitted" slot-scope="row">
        <div v-if="row.item.submitted">
           <i class="far fa-check-circle text-green" :title="$t('Sent')"></i>
         </div>
        <div v-else>
          <i class="far fa-times-circle text-red" :title="$t('Not Sent')"></i>
        </div>
      </template>
      <template slot="expired" slot-scope="row">
        <div v-if="row.item.expired">
           <i class="far fa-check-circle text-green" :title="$t('Exired')"></i>
         </div>
        <div v-else>
          <i class="far fa-times-circle text-red" :title="$t('Not Expired')"></i>
        </div>
      </template>
      <!-- <template slot="total" slot-scope="row">
        {{row.item.totals['total']}} €
      </template> -->
      <template slot="other-buttons-actions-bottom" slot-scope="row">
        <b-btn
          variant="outline-primary"
          v-has-perms="createNewRevisionRolePerm"
          :title="'New revision' | translate"
          @click.prevent="createNewRevision(row.item)"
        >
          <i class="fab fa-rev" />
        </b-btn>
        <b-btn
          variant="outline-primary"
          v-has-perms="downloadPDFRolePerm"
          :title="'Download PDF' | translate"
          @click.prevent="downloadPDF(row.item)"
        >
          <i class="fas fa-file-download"></i>
        </b-btn>
        <b-btn
          variant="outline-primary"
          v-has-perms="showSendEmailModalRolePerm"
          :title="'Send quotation by email' | translate"
          :disabled="row.item.is_closed"
          @click.prevent="showModal(row.item, 'showSendEmailModal')"
        >
          <i class="fas fa-envelope"></i>
        </b-btn>
        <b-btn
          variant="outline-primary"
          v-has-perms="showUploadSignedModalRolePerm"
          :title="'Upload signed' | translate"
          @click.prevent="showModal(row.item, 'showUploadSignedModal')"
        >
          <i class="fas fa-upload"></i>
        </b-btn>
        <b-btn
          :disabled="!row.item.signed_quotation"
          variant="outline-primary"
          v-has-perms="downloadQuotationSignedRolePerm"
          :title="'Download signed' | translate"
          @click.prevent="downloadQuotationSigned(row.item)"
        >
          <i class="fas fa-download"></i>
        </b-btn>
        <b-btn
          :disabled="disableAcceptButton(row.item)"
          variant="outline-primary"
          v-has-perms="acceptQuotationRolePerm"
          :title="'Accept quotation' | translate"
          @click.prevent="acceptQuotation(row.item)"
        >
          <i class="fas fa-handshake"></i>
        </b-btn>
      </template>
    </thux-table>
    <send-email-modal
      v-if="showSendEmailModal && quotation && quotation.id"
      :show-owner-emails='true'
      :title="$t('Send quotation by email')"
      :default-email="quotation.customer_default_email"
      @submit="sendQuotationEmail"
      @close="closeModal"
    />
    <file-upload-modal
      v-if="showUploadSignedModal && quotation && quotation.id"
      :title="$t('Upload signed')"
      @submit="uploadQuotationSigned"
      @close="closeModal"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as QUOTATION_TYPES } from './store'
import { ROUTES as QUOTATION_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxTableMixin from '../../../components/thux-table/ThuxTableMixin'
import ThuxTableChecksMixin from '../../../components/thux-table/thux-table-checks/ThuxTableChecksMixin'
import FileUploadModal from '../../../components/FileUploadModal'
import SendEmailModal from '../../../components/SendEmailModal'
import JobMixin from '../../../components/job-button/JobMixin'
import { storeMixin } from '../../../storeMixin'

export default {
  name: 'QuotationTable',
  components: { SendEmailModal, FileUploadModal },
  mixins: [ThuxTableMixin, ThuxTableChecksMixin, JobMixin],
  watch: {
    isYardQuotation () {
      this.setFields()
    }
  },
  data () {
    const fields = [
      {
        key: 'checks',
        label: this.$t('Checks')
      },
      {
        key: 'status',
        label: this.$t('Status'),
        tdClass: 'text-nowrap',
        thClass: 'text-nowrap',
        sortable: true,
        value: 'status'
      },
      {
        key: 'number',
        label: this.$t('Number'),
        tdClass: 'text-nowrap',
        thClass: 'text-nowrap',
        sortable: true,
        custom: true
      },
      {
        key: 'date_q',
        label: this.$t('Date'),
        tdClass: 'text-nowrap',
        thClass: 'text-nowrap',
        sortable: true,
        type: 'date',
        value: 'date_q'
      },
      {
        key: 'date_firm_for',
        label: this.$t('Date firm for'),
        tdClass: 'text-nowrap',
        thClass: 'text-nowrap',
        sortable: true,
        type: 'date',
        value: 'date_firm_for'
      },
      {
        key: 'customer__name',
        label: this.$t('Customer'),
        type: 'text',
        thClass: 'text-nowrap',
        tdClass: 'text-wrap',
        sortable: true,
        value: 'customer_name'
      },
      {
        key: 'description',
        label: this.$t('Description'),
        type: 'text',
        thClass: 'text-nowrap',
        tdClass: 'text-wrap',
        sortable: true,
        value: 'description'
      },
      // {
      //   key: 'total',
      //   label: this.$t('Tot.'),
      //   type: 'text',
      //   thClass: 'text-right',
      //   tdClass: 'text-right text-nowrap',
      //   sortable: false,
      //   custom: true
      // },
      {
        key: 'submitted',
        label: this.$t('Sent'),
        type: 'text',
        thClass: 'text-center',
        tdClass: 'text-center',
        sortable: true,
        custom: true
      },
      {
        key: 'order_done',
        label: this.$t('Accettato'),
        type: 'text',
        thClass: 'text-center',
        tdClass: 'text-center',
        sortable: true,
        custom: true
      },
      {
        key: 'expired',
        label: this.$t('Expired'),
        type: 'text',
        thClass: 'text-center',
        tdClass: 'text-center',
        sortable: false,
        custom: true
      },
      {
        key: 'action',
        label: this.$t('Actions'),
        tdClass: 'text-right text-nowrap',
        thClass: 'text-right text-nowrap'
      }
    ]
    if (this.$route.name === QUOTATION_ROUTES.YARD_QUOTATION_LIST) {
      fields.splice(5, 0, {
        key: 'clients_name',
        label: this.$t('Clients'),
        type: 'text',
        thClass: 'text-nowrap',
        tdClass: 'text-wrap',
        sortable: false,
        value: 'clients_name'
      })
    }
    return {
      apiCallJobUrl: `${this.apiUrl}/send-emails-quotation-job/`,
      showSendEmailModal: false,
      showUploadSignedModal: false,
      quotation: null,
      routeName: this.$route.name,
      fields: fields,
      fieldsColumn: ['status', 'number', 'date_q', 'date_firm_for', 'customer__name', 'description', 'submitted', 'order_done'],
      // Permissions
      createNewRevisionRolePerm: ['quotation_quotation_do_revision', 'quotation_quotationinstallment_retrieve', 'quotation_quotationrow_retrieve', 'payment_paymentform_retrieve', 'quotation_customerinvoiceaddress_retrieve'],
      downloadPDFRolePerm: 'quotation_quotation_print_pdf',
      showSendEmailModalRolePerm: 'quotation_quotation_send_emails_quotation_job',
      showUploadSignedModalRolePerm: 'quotation_quotation_partial_update',
      downloadQuotationSignedRolePerm: 'quotation_quotation_download_signed',
      acceptQuotationRolePerm: 'quotation_quotation_accept'
    }
  },
  created () {

  },
  computed: {
    ...mapGetters({
      apiUrl: QUOTATION_TYPES.GENERIC.quotation.quotation.LIST.GETTERS.apiUrl,
      selectAll: QUOTATION_TYPES.GENERIC.quotation.quotation.LIST.GETTERS.selectAll,
      selectedList: QUOTATION_TYPES.GENERIC.quotation.quotation.LIST.GETTERS.selectedList
    }),
    showChecks () {
      return true
    },
    showDetailButton () {
      return hasPerm('quotation_quotation_retrieve')
    },
    isYardQuotation () {
      if (this.$route.name === QUOTATION_ROUTES.YARD_QUOTATION_LIST) {
        return true
      }
      return false
    }
  },
  methods: {
    ...mapMutations({
      setList: QUOTATION_TYPES.GENERIC.quotation.quotation.LIST.MUTATIONS.setList,
      setSelectedList: QUOTATION_TYPES.GENERIC.quotation.quotation.LIST.MUTATIONS.setSelectedList,
      setSelectAll: QUOTATION_TYPES.GENERIC.quotation.quotation.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...QUOTATION_TYPES.GENERIC.quotation.quotation.LIST.ACTIONS,
      update: QUOTATION_TYPES.GENERIC.quotation.quotation.DETAIL.ACTIONS.update,
      partialUpdate: QUOTATION_TYPES.GENERIC.quotation.quotation.DETAIL.ACTIONS.partialUpdate,
      doRevision: QUOTATION_TYPES.GENERIC.quotation.quotation.DETAIL.ACTIONS.doRevision,
      accept: QUOTATION_TYPES.GENERIC.quotation.quotation.DETAIL.ACTIONS.accept
    }),
    showModal (item, showModalName) {
      this.$set(this, 'quotation', item)
      this.$set(this, showModalName, true)
    },
    closeModal () {
      this.$set(this, 'quotation', null)
      this.$set(this, 'showSendEmailModal', false)
      this.$set(this, 'showUploadSignedModal', false)
    },
    sendQuotationEmail (emails) {
      this.$set(this, 'apiCallJobUrl', `${this.apiUrl}/${this.quotation.id}/send-emails-quotation-job/`)
      this.doJob({ quotation_id: this.quotation.id, emails: emails })
    },
    afterExecuteJob () {
      this.closeModal()
    },
    uploadQuotationSigned (file) {
      let formData = { id: this.quotation.id }
      formData.signed_quotation = file
      formData = storeMixin.utils.parseFormMultipart(formData, ['signed_quotation'])
      this.partialUpdate(formData)
    },
    downloadQuotationSigned (item) {
      this.download({ id: item.id, action: 'download-signed', simpleFileName: item.signed_filename })
    },
    downloadPDF (item) {
      const fileName = `${this.$t('Quotation')}_${item.number}_${item.date_q}.pdf`
      this.download({ id: item.id, action: 'print-pdf', simpleFileName: fileName })
    },
    createNewRevision (item) {
      this.doRevision(item.id).then(
        (response) => {
          if (this.isYardQuotation) {
            this.$router.push({ name: QUOTATION_ROUTES.YARD_QUOTATION_DETAIL, params: { id: response.id } })
          } else {
            this.$router.push({ name: QUOTATION_ROUTES.QUOTATION_DETAIL, params: { id: response.id } })
          }
        })
    },
    acceptQuotation (item) {
      this.accept(item.id).then(() => {
        if (this.isYardQuotation) {
          this.$router.push({ name: QUOTATION_ROUTES.QUOTATION_CREATE_YARD, params: { quotationId: item.id } })
        }
      })
    },
    disableAcceptButton (item) {
      if (this.isYardQuotation) {
        return !item.signed_quotation || item.has_yards
      } else {
        return !item.signed_quotation || item.order_done
      }
    },
    setFields () {
      const fields = [
        {
          key: 'checks',
          label: this.$t('Checks')
        },
        {
          key: 'status',
          label: this.$t('Status'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'status'
        },
        {
          key: 'number',
          label: this.$t('Number'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          custom: true
        },
        {
          key: 'date_q',
          label: this.$t('Date'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          type: 'date',
          value: 'date_q'
        },
        {
          key: 'date_firm_for',
          label: this.$t('Date firm for'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          type: 'date',
          value: 'date_firm_for'
        },
        {
          key: 'customer__name',
          label: this.$t('Customer'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-wrap',
          sortable: true,
          value: 'customer_name'
        },
        {
          key: 'description',
          label: this.$t('Description'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-wrap',
          sortable: true,
          value: 'description'
        },
        {
          key: 'total',
          label: this.$t('Tot.'),
          type: 'text',
          thClass: 'text-right',
          tdClass: 'text-right text-nowrap',
          sortable: false,
          custom: true
        },
        {
          key: 'submitted',
          label: this.$t('Sent'),
          type: 'text',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
          custom: true
        },
        {
          key: 'order_done',
          label: this.$t('Accettato'),
          type: 'text',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
          custom: true
        },
        {
          key: 'expired',
          label: this.$t('Expired'),
          type: 'text',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: false,
          custom: true
        },
        {
          key: 'action',
          label: this.$t('Actions'),
          tdClass: 'text-right text-nowrap',
          thClass: 'text-right text-nowrap'
        }
      ]
      if (this.$route.name === QUOTATION_ROUTES.YARD_QUOTATION_LIST) {
        fields.splice(5, 0, {
          key: 'clients_name',
          label: this.$t('Clients'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-wrap',
          sortable: false,
          value: 'clients_name'
        })
      }
      this.$set(this, 'fields', fields)
    }
  }
}
</script>
